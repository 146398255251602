import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { PlatformService } from '../platform/platform.service';

import { AuthRequest } from 'src/app/models/accounts/contracts/auth-request';
import { AuthResponse } from 'src/app/models/accounts/contracts/auth-response';

import strings from './api.service.strings';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly baseUrl: string;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private platformService: PlatformService
    ) {
        this.baseUrl = strings.baseUrls[strings.environment];
        // this.currentJwt = null;
    }

    public getBaseUrl() {
        return this.baseUrl;
    }

    public async get(route: string) {
        const headers = await this.getHeaders();
        return new Promise((resolve, reject) => {
            this.http.get(`${this.baseUrl}${route}`, {
                headers
            })
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    },
                    (error: any) => {
                        reject(JSON.stringify(error));
                    }
                )
        }
        );
    }

    public async post(route: string, data: any) {
        const headers = await this.getHeaders();
        return new Promise((resolve, reject) => {
            this.http.post(`${this.baseUrl}${route}`, data, {
                headers
            })
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    },
                    (error: any) => {
                        reject(JSON.stringify(error));
                    }
                )
        }
        );
    }

    public async put(route: string, data: any) {
        const headers = await this.getHeaders();
        return new Promise((resolve, reject) => {
            this.http.put(`${this.baseUrl}${route}`, data, {
                headers
            })
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    },
                    (error: any) => {
                        reject(JSON.stringify(error));
                    }
                )
        }
        );
    }

    public async delete(route: string) {
        const headers = await this.getHeaders();
        return new Promise((resolve, reject) => {
            this.http.delete(`${this.baseUrl}${route}`, {
                headers
            })
                .subscribe(
                    (response: any) => {
                        resolve(response);
                    },
                    (error: any) => {
                        reject(JSON.stringify(error));
                    }
                )
        }
        );
    }

    private async getHeaders() {
        const currentJwt = await this.getJwt();
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        if (currentJwt && currentJwt.access_token) {
            headers = headers.append('Authorization', `Bearer ${currentJwt.access_token}`);
        }

        return headers;
    }

    /*
    private convertDates(data: any) {
        const entries = Object.entries(data);
        for (const [key, value] of entries) {
            const isDate = moment(value, moment.ISO_8601).isValid();
  
            if (isDate) {
                data[key] = moment(value as string, 'YYYY-MM-DD HH:mm'); // Use this to get UTC and ignore timezone (good when you need just the date)
                data[key] = moment(value as string, 'YYYY-MM-DD HH:mmZ'); // Use this to include timezone adjustment
            }
        }
    }
    */

    private async getJwt() {
        const currentJwt = (await this.localStorageService.getData('jwt')) as AuthResponse;

        if (currentJwt) {
            const jwt = jwt_decode(currentJwt.access_token) as any;
            // console.log(`Token exp validation: ${Date.now()} <= ${jwt.exp * 1000}`);

            if (Date.now() >= jwt.exp * 1000) {
                this.platformService.signOut();
            }
        }

        return currentJwt;
    }

    private async refreshJwt(currentJwt: AuthResponse) {
        return await this.getAuthToken({
            grant_type: 'refresh_token',
            refresh_token: currentJwt.refresh_token
        });
    }

    public async getAuthToken(request: AuthRequest) {
        const headers = await this.getHeaders();
        const response: AuthResponse = await this.http
            .post<AuthResponse>(`${this.getBaseUrl()}${strings.endpoints.authToken}`, request, { headers: headers })
            .toPromise()
            .catch((error: any) => {
                return null;
            });

        if (response && response.access_token) {
            await this.localStorageService.setData('jwt', response);
            return response;
        }

        return null;
    }
}
