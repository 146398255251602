import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { IonInput, MenuController } from '@ionic/angular';
import { PlatformService } from 'src/app/services/platform/platform/platform.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() showSearchToggle = false;
  @Input() showSearch = false;
  @Input() searchTitle = '';
  @Input() hideMenu = false;
  @Input() showMenuClose = false;
  
  @Output() onSearchEvent = new EventEmitter<string>();

  constructor(private menu: MenuController, private platformService: PlatformService) { }

  ngOnInit() { }

  public onSearch(searchText: string) {
    this.onSearchEvent.emit(searchText);
  }
}
