import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PageHeaderComponent } from './platform/page-header/page-header.component';

const componentList = [PageHeaderComponent];

@NgModule({
    declarations: componentList,
    imports: [CommonModule, FormsModule, IonicModule],
    exports: componentList
})
export class CommonComponentsModule { }
