import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    AlertController,
    AlertOptions,
    LoadingController,
    ModalController,
    Platform,
    ToastController,
    ToastOptions
} from '@ionic/angular';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  public isSearchEnabled = false;
  public searchText = '';

  constructor(
      private platform: Platform,
      private alertController: AlertController,
      private toastController: ToastController,
      private modalController: ModalController,
      private loadingController: LoadingController,
      private localStorageService: LocalStorageService,
      private router: Router
  ) {}

  public getIsIOS() {
      return this.platform.is('ios') && !this.getIsBrowser();
  }

  public getIsAndroid() {
      return this.platform.is('android') && !this.getIsBrowser();
  }

  public getIsBrowser() {
      return !(this.platform.is('cordova') || this.platform.is('capacitor'));
  }

  public getIsBrowserIOS() {
      return this.platform.is('ios') && this.getIsBrowser();
  }

  public getIsBrowserAndroid() {
      return this.platform.is('android') && this.getIsBrowser();
  }

  public getLocale() {
      if (navigator.language !== undefined) {
          return navigator.language;
      } else {
          return 'en-US';
      }
  }

  public navigate(path: string) {
      this.router.navigateByUrl(path);
  }

  public async signOut() {
      await this.localStorageService.resetData();

      if (window.location.href.indexOf('/sign-in') < 0) {
          window.location.href = '/sign-in';
      }
  }

  public getCanShowActionSheetIcons() {
      return !this.getIsIOS() && !this.getIsBrowserIOS();
  }

  public async showAlert(message: string, buttons: any[] = null) {
      if (!buttons) {
          buttons = [{ text: 'Ok' }];
      }

      const alertOptions: AlertOptions = {
          animated: true,
          backdropDismiss: false,
          message: message,
          buttons: buttons
      };
      const alertDialog = await this.alertController.create(alertOptions);
      alertDialog.present();
  }

  public async showToast(message: string, buttons: any[] = null) {
      const toastOptions: ToastOptions = {
          message: message,
          position: 'top',
          color: 'dark',
          buttons: buttons
      };
      const toast = await this.toastController.create(toastOptions);
      toast.present();
  }

  public async showModal(modalComponent: any, props: any, css: any) {
      const modalToShow = await this.modalController.create({
          component: modalComponent,
          componentProps: props,
          cssClass: css
      });
      return await modalToShow.present();
  }

  public async showLoading() {
      return this.showWorking('Loading...');
  }

  public async showUpdating() {
      return this.showWorking('Updating...');
  }

  public async showDeleting() {
      return this.showWorking('Deleting...');
  }

  public async showWorking(message) {
      const loading = await this.loadingController.create({
          message: message ? message : 'Working...',
          showBackdrop: true
      });
      await loading.present();
      return loading;
  }

  public getDateTimeAsDate(value: Date) {
      const dateString = value.getFullYear() + '-' + value.getMonth() + '-' + value.getDay();
      return new Date(dateString);
  }
}
