import { Component } from '@angular/core';
import { Platform, MenuController } from '@ionic/angular';
import { AuthService } from './services/accounts/auth/auth.service';
import { OrganizationService } from './services/accounts/organization/organization.service';
import { LocalStorageService } from './services/platform/local-storage/local-storage.service';
import { PlatformService } from './services/platform/platform/platform.service';
import { Organization } from './models/accounts/organization';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private platformService: PlatformService,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private menu: MenuController,
    private localStorageService: LocalStorageService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      if (
        window.location.protocol === 'http:' &&
        window.location.host.indexOf('mqstracker.com') >= 0
      ) {
        window.location.href = 'https://' + window.location.host;
        return;
      }

      await this.localStorageService.init();
      const isAuthenticated = await this.authService.isAuthenticated();
      this.menu.enable(isAuthenticated);
      this.showGpdrToast();

      if (!isAuthenticated) {
        this.platformService.signOut();
      } else {
        await this.organizationService.getCurrentOrganization();

        if (!this.organizationService.currentOrganization) {
          this.authService.signOut();
        }
      }
    });
  }

  async showGpdrToast() {
    const storageKey = 'gpdr-accept';
    const hasAccepted = await this.localStorageService.getData(storageKey);

    if (hasAccepted) return;

    await this.platformService.showToast('This site uses cookies to analyze traffic and improve the experience.', [
      { text: 'Ok', handler: () => { this.localStorageService.setData(storageKey, true); }}
    ]);
  }

  async onMenuItem(menuItem) {
    this.platformService.navigate(`/${menuItem}`);
  }

  async onSignOut() {
    await this.authService.signOut();
    this.menu.enable(await this.authService.isAuthenticated());
    this.platformService.navigate('/sign-in');
  }
}
