import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private cache: any = {};

  constructor(private storage: Storage) {
  }

  public async init(): Promise<boolean> {
    // This is for initializing any data startup tasks
    await this.storage.create();
    return true;
  }

  public async getData(key: string): Promise<any> {
    // this.platformService.output('local-storage:getData');
    // this.platformService.output('Getting value for key: ' + key);
    const value = await this.storage.get(key);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  public async setData(key: string, data: any): Promise<any> {
    // this.platformService.output('local-storage:setData');
    const value = data ? JSON.stringify(data) : undefined;
    // this.platformService.output('Storing key/value: ' + key + ' / ' + value);
    return await this.storage.set(key, value);
  }

  public async resetData(): Promise<void> {
    this.cache = {};
    this.storage.clear();

    setTimeout(() => {
      return;
    }, 3600);
  }
}
