import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { Account } from 'src/app/models/accounts/account';
import { AuthResponse } from 'src/app/models/accounts/contracts/auth-response';
import { Organization } from 'src/app/models/accounts/organization';
import { ApiService } from '../../platform/api/api.service';
import { LocalStorageService } from '../../platform/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  public async getCurrentOrganization() {
    const currentJwt: AuthResponse = await this.localStorageService.getData('jwt');
    const jwt = jwt_decode(currentJwt.access_token);
    return JSON.parse(jwt['organization']) as Organization;
  }

  public async getCurrentAccount() {
    const currentJwt: AuthResponse = await this.localStorageService.getData('jwt');
    const jwt = jwt_decode(currentJwt.access_token);
    return JSON.parse(jwt['account']) as Account;
  }

  public async isAuthenticated() {
    const currentJwt: AuthResponse = await this.localStorageService.getData('jwt');

    if (currentJwt && currentJwt.access_token) return true;

    return false;
  }

  public async authenticate(username: string, password: string) {
    // this.apiService.setAuthToken('');

    const response = await this.apiService.getAuthToken({
      grant_type: 'password',
      username: username,
      password: password
    });

    if (response) {
      // await this.localStorageService.setData('jwt', response.access_token);
      // await this.updateJwt(response);
      return true;
    }

    return false;
  }

  public async signOut(): Promise<void> {
    // await this.updateJwt(null);
    await this.localStorageService.resetData();
    return;
  }
}
