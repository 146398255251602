import { Injectable } from '@angular/core';
import { AuthResponse } from 'src/app/models/accounts/contracts/auth-response';
import { Organization } from 'src/app/models/accounts/organization';
import { OrganizationAddress } from 'src/app/models/accounts/organization-address';
import { ApiService } from '../../platform/api/api.service';
import strings from './organization.service.strings';
import { LocalStorageService } from '../../platform/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  public currentOrganization: Partial<Organization> = {
    name: ''
  };

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService) {
  }

  public async update(request: Organization) {
    const response = (await this.apiService.post(strings.apiRoutes.update, request)) as AuthResponse;
    await this.localStorageService.setData('jwt', response);
    return response;
  }

  public async createAddress(request: OrganizationAddress) {
    return (await this.apiService.put(strings.apiRoutes.createAddress, request)) as OrganizationAddress[];
  }

  public async getAddresses() {
    return (await this.apiService.get(strings.apiRoutes.getAddresses)) as OrganizationAddress[];
  }

  public async getCurrentOrganization() {
    this.currentOrganization = await this.apiService.get(strings.apiRoutes.getCurrent);
    return this.currentOrganization;
  }
}
