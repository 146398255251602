const strings = {
    apiRoutes: {
        update: "/v1/organizations",
        create: "/v1/organizations",
        get: "/v1/organizations",
        getCurrent: "/v1/organizations/current",
        createAddress: "/v1/organizations/addresses",
        updateAddress: "/v1/organizations/addresses",
        getAddresses: "/v1/organizations/addresses"
    }
};
export default strings;
