const strings = {
    environment: 'dev',
    baseUrls: {
        dev: 'http://localhost:5000',
        prod: 'http://api.mqstracker.com'
    },
    endpoints: {
        authToken: '/v1/auth/token'
    }
};
export default strings;
